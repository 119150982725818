import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Main from "../components/Sumate/main"

const IndexPage = () => (
  <Layout>
    <Seo title="Sumate" />
    <Main />
  </Layout>
)

export default IndexPage
