import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "react-bootstrap"
import CloseButton from "react-bootstrap/CloseButton"
import Form from "react-bootstrap/Form"
import ReCAPTCHA from "react-google-recaptcha"
import toast from "react-hot-toast"
import { connect } from "react-redux"
import { useCreateApplicationMutation } from "../../redux/mediacore/jobs"

const FormJobs = props => {
  const { jobData, stateProp, captcha, closeBtnAction, clientId } = props
  const [disabled, setDisabled] = useState(true)
  const blankData = {
    position: jobData?.id,
    location: "",
    gender: "",
    email: "",
    phone: "",
    extra_info: { visualizedSteps: [0] },
    languages: {},
    interviews: {},
    portfolio: {},
    status: "En revisión",
    birth_date: "",
    cv: null,
    letter: "",
  }
  const [data, setData] = useState(blankData)

  const [createApplication, { isLoading: isLoadingApplication }] =
    useCreateApplicationMutation()

  const handleInputChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }
  const formatDateToISOString = dateString => {
    if (!dateString) {
      return ""
    }
    const date = new Date(dateString)
    date.setUTCHours(12)
    const formattedDate = date.toISOString().slice(0, -5) + "Z"
    return formattedDate
  }
  const handleDateChange = e => {
    e.persist()
    setData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }))
  }

  const handleFileInput = e => {
    e.persist()
    const file = e.target.files[0]
    setData(prevData => ({
      ...prevData,
      cv: file,
    }))
  }

  useEffect(() => {
    if (!captcha) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [])

  const handleSubmit = () => {
    const formData = {
      ...data,
      is_active: true,
      client: clientId,
      birth_date: formatDateToISOString(data?.birth_date ?? ""),
      cv: data?.cv ?? "",
    }

    createApplication({ data: formData, CLIENT_ID: clientId })
      .unwrap()
      .then(() => {
        toast.success("¡Mensaje enviado correctamente!")
        setData(blankData)
      })
      .catch(error => toast.error("Error al enviar al formulario"))
  }

  return (
    <div
      className={
        "position-relative mediajobs-form" + (stateProp ? " active" : "")
      }
      id={`form-id-${jobData?.id}`}
    >
      <Container fluid>
        <Row>
          <Col xs={12} className="text-end my-3">
            <CloseButton
              className="my-2 my-0 mt-lg-5 mb-lg-3"
              onClick={() => closeBtnAction()}
            />
          </Col>
          <Form
            onSubmit={e => {
              e.preventDefault()
              handleSubmit()
            }}
            className="content-form"
            id="jobsForm"
          >
            <Row>
              <Col xs={12} lg={6} className="pe-lg-5 mb-4">
                <Row className="my-3">
                  <h3>Datos personales</h3>
                </Row>
                <Row className="mb-0 mb-md-3">
                  <Form.Group as={Col} xs={12} lg={6} controlId="formGridName">
                    <input
                      name="first_name"
                      value={data?.first_name ?? ""}
                      type="text"
                      placeholder="Nombre y apellido*"
                      onChange={handleInputChange}
                      autoComplete="off"
                      autoFocus
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={12}
                    lg={6}
                    controlId="formGridGender"
                    className="text-input-jobs"
                  >
                    <select
                      name="gender"
                      value={data?.gender ?? ""}
                      defaultValue="Género"
                      onChange={handleInputChange}
                      // className="custom-select"
                    >
                      <option value="" disabled selected hidden>
                        Género
                      </option>
                      <option value="No deseo especificar">
                        No deseo especificar
                      </option>
                      <option value="Hombre">Hombre</option>
                      <option value="Mujer">Mujer</option>
                    </select>
                    <i></i>
                  </Form.Group>
                </Row>
                <Row className="mb-0 mb-md-3">
                  <Form.Group
                    as={Col}
                    xs={12}
                    lg={6}
                    controlId="formGridDate"
                    className="mt-lg-0"
                  >
                    <input
                      name="birth_date"
                      type="date"
                      onChange={handleDateChange}
                      value={data?.birth_date ?? ""}
                      autoComplete="off"
                      style={{ cursor: "pointer" }}
                      className="date-input"
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={12}
                    lg={6}
                    controlId="formGridSurname"
                    className="mt-lg-0 text-input-jobs"
                  >
                    <input
                      name="residence"
                      value={data?.residence ?? ""}
                      type="text"
                      placeholder="Lugar de residencia"
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Row>
                {/* <Row className="my-3 mt-5">
                  <h3>Datos adicionales</h3>
                </Row>
                <Row className="mb-0 mb-md-3">
                  <Form.Group
                    as={Col}
                    xs={12}
                    controlId="formGridWp"
                    className="text-input-jobs"
                  >
                    <Form.Label className="mb-1">
                      Curriculum vitae / CV (Opcional). Archivos compatibles:{" "}
                      <sup>*</sup>pdf, <sup>*</sup>
                      doc, <sup>*</sup>jpg
                    </Form.Label>
                    <input
                      name="cv"
                      type="file"
                      onChange={e => handleFileInput(e)}
                      autoComplete="off"
                      style={{ cursor: "pointer" }}
                    />
                  </Form.Group>
                </Row> */}
                {/* <Row className="mb-0 mb-md-3">
                  <Form.Group
                    as={Col}
                    xs={12}
                    lg={12}
                    controlId="formGridLetter"
                    className="mt-lg-0 text-input-jobs"
                  >
                    <textarea
                      name="letter"
                      value={data?.letter ?? ""}
                      cols="30"
                      rows="5"
                      onChange={handleInputChange}
                      placeholder="Carta de presentación (opcional)"
                    ></textarea>
                  </Form.Group>
                </Row> */}
              </Col>

              <Col lg={6} xs={12} className="form-text">
                <Row className="my-3">
                  <h3>Datos de contacto</h3>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} xs={12} lg={6} controlId="formGridEmail">
                    <input
                      name="email"
                      type="email"
                      placeholder="Email*"
                      onChange={handleInputChange}
                      value={data?.email ?? ""}
                      autoComplete="off"
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    xs={12}
                    lg={6}
                    controlId="formGridPhone"
                    className="mt-lg-0"
                  >
                    <input
                      name="phone"
                      value={data?.phone ?? ""}
                      type="text"
                      placeholder="WhatsApp"
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xs={12} lg={6} className="pe-lg-5 mb-4">
                <Row className="my-3 mt-5">
                  <h3>Datos adicionales</h3>
                </Row>
                <Row className="mb-0 mb-md-3">
                  <Form.Group
                    as={Col}
                    xs={12}
                    controlId="formGridWp"
                    className="text-input-jobs"
                  >
                    <Form.Label className="mb-1">
                      Curriculum vitae / CV (Opcional). Archivos compatibles:{" "}
                      <sup>*</sup>pdf, <sup>*</sup>
                      doc, <sup>*</sup>jpg
                    </Form.Label>
                    <input
                      name="cv"
                      type="file"
                      onChange={e => handleFileInput(e)}
                      autoComplete="off"
                      style={{ cursor: "pointer" }}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-0 mb-md-3">
                  <Form.Group
                    as={Col}
                    xs={12}
                    lg={12}
                    controlId="formGridLetter"
                    className="mt-lg-0 text-input-jobs"
                  >
                    <textarea
                      name="letter"
                      value={data?.letter ?? ""}
                      cols="30"
                      rows="5"
                      onChange={handleInputChange}
                      placeholder="Carta de presentación (opcional)"
                    ></textarea>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row className="mb-5">
              <Form.Group as={Col} xs={12} controlId="formGridCaptcha">
                {captcha ? (
                  <ReCAPTCHA
                    sitekey={captcha}
                    onChange={() => setDisabled(false)}
                  />
                ) : null}
              </Form.Group>
              <Form.Group as={Col} xs={12} lg={6} controlId="formGridSubmit">
                <div className="col-12 mt-5 text-center text-lg-start">
                  <button
                    type="submit"
                    style={{ background: "transparent", border: "none" }}
                    disabled={disabled}
                  >
                    <div className="btn-container-form">
                      <a
                        className={disabled ? "btn-disabled" : "btn-round-form"}
                        disabled={disabled}
                      >
                        <i className="icon-arrow-right"></i>
                      </a>
                      <a
                        className="link-back"
                        disabled={disabled}
                        style={
                          disabled ? { color: "#cacaca", cursor: "unset" } : {}
                        }
                      >
                        {isLoadingApplication ? "Enviando..." : "Aplicar"}
                      </a>
                    </div>
                  </button>
                </div>
              </Form.Group>
            </Row>
          </Form>
        </Row>
      </Container>
    </div>
  )
}

export default connect()(FormJobs)
